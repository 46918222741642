import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Placeholder from "../components/header"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"
import Features from "../components/features"
import LinkList from "../components/linkList"

class Staff extends Component {
  render() {
    const currentPage = this.props.data.allWpPage.edges[0].node,
      pageId =
        currentPage.parentId === 0 ? currentPage.id : currentPage.parentId,
      pageTitle = currentPage.parentId === 0 ? currentPage.title : false,
      introTitle =
        currentPage.introduction != null
          ? currentPage.introduction.introTitle
          : "",
      introDesc =
        currentPage.introduction != null ? currentPage.introduction.intro : "",
      staff = currentPage.staff != null ? currentPage.staff.staff : "",
      seoTitle = currentPage.title,
      seoDesc = ""

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />
          <PageTitle pageId={pageId} pageTitle={pageTitle} />
          <PageIntro introTitle={introTitle} introDesc={introDesc} />

          <section className="section sectionWhite section--close-btm">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12 col-bp4-4">
                  <h3 className="section__title">
                    <span>In this section</span>
                  </h3>
                  <div className="linkListCompact">
                    <LinkList
                      parentId={currentPage.parentId}
                      pageSlug={currentPage.slug}
                    />
                  </div>
                </div>
                <div className="col-bp1-12 col-bp4-7">
                  <div className="entry">
                    <div
                      dangerouslySetInnerHTML={{ __html: currentPage.content }}
                    ></div>
                  </div>

                  <ul className="staffMembers">
                    {staff.map((member, i) => {
                      return (
                        <li key={i} className="staffMember">
                          {staffImage(member.staffMemberImage)}
                          <div className="staffMember__content">
                            <h3 className="staffMember__title">
                              {member.staffMemberTitle}
                              {memberRole(member.staffMemberRole)}
                            </h3>
                            <div
                              className="staffMember__bio"
                              dangerouslySetInnerHTML={{
                                __html: member.staffMemberBio,
                              }}
                            ></div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="section sectionWhite section--close-btm">
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

function memberRole(role) {
  return role !== "" ? <span> - {role}</span> : ""
}

function staffImage(imgURL) {
  if (imgURL !== null) {
    const name = imgURL.localFile.name
    const imgRes = imgURL.localFile.childImageSharp.fluid
    return (
      <div className="staffMember__img">
        <Img fluid={imgRes} key={name} alt={name} />
      </div>
    )
  } else {
    return <Placeholder />
  }
}

export default Staff

export const pageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          databaseId
          title
          content
          parentId
          slug
          introduction {
            introTitle
            intro
          }
          staff {
            staff {
              staffMemberTitle
              staffMemberRole
              staffMemberBio
              staffMemberImage {
                localFile {
                  name
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
