import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

// need to get the menu name

const LinkList = props => (
  <StaticQuery
    query={graphql`
      query {
        allWpMenu {
          edges {
            node {
              name
              slug
              menuItems {
                nodes {
                  label
                  path
                }
              }
            }
          }
        }
        allWpPage {
          edges {
            node {
              id
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <ul className="linkList">
        {data.allWpMenu.edges.map(({ node }) =>
          filterMenuItems(
            props,
            node.slug,
            data.allWpMenu.edges,
            data.allWpPage.edges
          )
        )}
      </ul>
    )}
  />
)

function filterMenuItems(props, menuSlug, menuItems, pageData) {
  // If we're on a parent page, create list of Menu items,
  // determined by our current page slug
  if (props.parentId == null) {
    if (menuSlug === props.pageSlug) {
      return menuItems.map(({ node }) => {
        return buildLinkList(props.pageSlug, node)
      })
    }
  } else {
    // If not on a parent page
    // get the parent page slug of the current page
    // then create list of menu items using that parent slug

    // So.. get the data of all pages first
    // then filter that by the props.parentId we passed
    // to get the parent page slug
    return pageData.map(({ node }) => {
      return filterChildMenus(menuSlug, props, node, menuItems)
    })
  }
}

function filterChildMenus(menuSlug, props, pageNode, menuItems) {
  // Compare parentId of the page to get the parent Page by wordpressId
  if (props.parentId === pageNode.id) {
    // now build list of links using the slug that corresponds to this
    if (menuSlug === pageNode.slug) {
      return menuItems.map(({ node }) => {
        return buildLinkList(pageNode.slug, node)
      })
    }
  }
}

function buildLinkList(pageSlug, node) {
  // If the name of the menu in the loop
  // corresponds to the current page slug
  // create our list of links
  if (node.name === pageSlug) {
    return node.menuItems.nodes.map(item => (
      <li key={item.label} className="linkList__item">
        <Link to={item.path} activeClassName="active">
          {formatAmpersand(item.label)}
        </Link>
      </li>
    ))
  }
}

function formatAmpersand(string) {
  return string.replace("&#038;", "&")
}

export default LinkList
