import React from "react"

const PageIntro = props => (
  <section className="section sectionBlue">
    <div className="containerFluid">
      <div className="row center-bp1">
        <div className="col-bp1-12 col-bp2-9 col-bp6-6 col-bp7-6">
          <div className="intro">
            {showTitle(props.introTitle)}
            {showIntro(props.introDesc, props.introTitle)}
          </div>
        </div>
      </div>
    </div>
  </section>
)

function showTitle(title) {
  if (title != null && title !== "")
    return (
      <h2
        dangerouslySetInnerHTML={{ __html: title }}
        className="intro__title"
      ></h2>
    )
}

function showIntro(intro, title) {
  if (intro !== "") {
    let classNames =
      title === "" || title == null
        ? "intro__desc intro__desc--title"
        : "intro__desc"
    return (
      <div
        dangerouslySetInnerHTML={{ __html: intro }}
        className={classNames}
      ></div>
    )
  }
}

export default PageIntro
