import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const PageTitle = ({ pageId, pageTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `}
    render={data => (
      <div className="pageTitleContainer">
        <div className="containerFluid">
          <div className="row">
            <div className="col-bp1-12">
              <div className="pageTitle">
                {getTitle(data, pageId, pageTitle)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

function getTitle(data, pageId, pageTitle) {
  if (pageTitle) {
    return <h1>{pageTitle}</h1>
  } else {
    return data.allWpPage.edges.map(({ node }) => {
      if (node.id === pageId) {
        return <h1 key={node.title}>{node.title}</h1>
      }
      return false
    })
  }
}

PageTitle.propTypes = {
  edges: PropTypes.array,
}

export default PageTitle
