import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Placeholder = () => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp(
          filter: {
            fluid: {
              originalName: { eq: "11_Cowboy_love_by_Natalie_Young_2011.jpg" }
            }
          }
        ) {
          edges {
            node {
              id
              gatsbyImageData
            }
          }
        }
      }
    `}
    render={data =>
      data.allImageSharp.edges.map(({ node }) => (
        <GatsbyImage
          className="block__img"
          key={node.id}
          image={getImage(node)}
          alt={node.id}
        />
      ))
    }
  />
)

export default Placeholder
