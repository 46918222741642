import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Placeholder from "../components/placeholder"
import LinkCog from "../components/linkCog"

const Features = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage {
          edges {
            node {
              databaseId
              title
              content
              uri
              featureBlock {
                featureBlockTitle
                featureBlockDesc
              }
              featuredImage {
                node {
                  localFile {
                    name
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        allWpPost(limit: 1) {
          edges {
            node {
              title
              content
              date(formatString: "MMMM DD, YYYY")
              slug
              featuredImage {
                node {
                  localFile {
                    name
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="blocks">
        <div className="block">
          {data.allWpPage.edges
            .filter(({ node }) => {
              return node.databaseId === 37
            })
            .map(({ node }, i) => {
              const featureTitle = node.featureBlock
                ? node.featureBlock.featureBlockTitle
                : node.title
              const featureDesc = node.featureBlock
                ? node.featureBlock.featureBlockDesc
                : node.content

              return (
                <div key={i}>
                  {featuredImage(node.featuredImage.node)}
                  <div className="block__content block__content1">
                    <h3 className="block__title">
                      <Link to={node.uri}>{featureTitle}</Link>
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: excerpt(featureDesc, 120),
                      }}
                    />
                    <Link to={node.uri} className="block__link">
                      <div className="block__linkCog block__linkCog--blue">
                        <LinkCog />
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
        </div>
        <div className="block">
          {data.allWpPost.edges.map(({ node }, i) => {
            return (
              <div key={i}>
                {featuredImage(node.featuredImage.node, `/${node.slug}`)}
                <div className="block__content block__content2">
                  <h3 className="block__title">
                    <Link to={`/${node.slug}`}>{node.title}</Link>
                  </h3>
                  <p>{excerpt(node.content, 120)}</p>
                  <Link to={`/${node.slug}`} className="block__link">
                    <div className="block__linkCog block__linkCog--light-blue">
                      <LinkCog />
                    </div>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
        <div className="block">
          {data.allWpPage.edges
            .filter(({ node }) => {
              return node.databaseId === 54
            })
            .map(({ node }, i) => {
              const featureTitle =
                node.title !== ""
                  ? node.title
                  : node.featureBlock.featureBlockTitle
              const featureDesc =
                node.featureBlock.featureBlockDesc === ""
                  ? node.content
                  : node.featureBlock.featureBlockDesc
              return (
                <div key={i}>
                  {featuredImage(node.featuredImage.node, node.slug)}
                  <div className="block__content block__content1">
                    <h3 className="block__title">
                      <Link to={node.uri}>{featureTitle}</Link>
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: excerpt(featureDesc, 120),
                      }}
                    />
                    <Link to={node.uri} className="block__link">
                      <div className="block__linkCog block__linkCog--blue">
                        <LinkCog />
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )}
  />
)

function featuredImage(imgURL, postSlug) {
  if (imgURL === null) {
    return (
      <Link to={postSlug ? postSlug : "#"} className="block__image">
        <Placeholder />
      </Link>
    )
  } else {
    const name = imgURL.localFile.name
    const imgRes = getImage(imgURL.localFile)
    return (
      <Link to={postSlug ? postSlug : "#"} className="block__image">
        <GatsbyImage image={imgRes} className="block__img" alt={name} />
      </Link>
    )
  }
}

function excerpt(snippet, length) {
  if (typeof document !== "undefined") {
    let el = document.createElement("div")
    el.innerHTML = snippet
    let text = el.textContent || el.innerText || ""
    return text.substring(0, length) + "..."
  }
}

export default Features
